html {
  font-family: Avenir;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body{
  margin: 0 !important;
}

/*Makes icons use provided color instead of their default color*/
.app_icon_class {
  fill: currentColor !important
}